import React, { Component } from "react"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { Modal, Header, Segment, Grid, Label } from "semantic-ui-react"

class PaymentActivityModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      modalOpen: false
    }
  }

  loadPaymentActivity = () => {
    const { payment_activity_id } = this.props
    this.props.dispatch({
      type: "PAYMENT_ACTIVITY_LOAD_SAGA",
      payload: {
        options: {
          payment_activity_id: payment_activity_id
        }
      },
      callback: this.afterRequestSuccess
    })
  }

  afterRequestSuccess = () => {
    this.setState({ modalOpen: true })
  }

  handleOpen = async () => {
    this.loadPaymentActivity()
  }

  handleClose = () => {
    this.setState({ modalOpen: false })
  }

  renderView = () => {
    const { payment_activity, t } = this.props

    return (
      <React.Fragment>
        <Header textAlign="center" className="primary-color">
          {t("transactionDetails")}
        </Header>
        <Segment>
          <Grid.Row>
            <Label> {t("shopName")} </Label> {payment_activity.shop_name || "N/A"}
          </Grid.Row>
          <Grid.Row>
            <Label> {t("vehicle")} </Label> {payment_activity.vehicle_name || "N/A"}
          </Grid.Row>
          <Grid.Row>
            <Label> {t("vin")} </Label> {payment_activity.vin || "N/A"}
          </Grid.Row>
          <Grid.Row>
            <Label> {t("otherId")} </Label> {payment_activity.other_id || "N/A"}
          </Grid.Row>
          <Grid.Row>
            <Label> {t("poNumber")} </Label> {payment_activity.po_number || "N/A"}
          </Grid.Row>
          <Grid.Row>
            <Label> {t("departmentNumber")} </Label> {payment_activity.department_number || "N/A"}
          </Grid.Row>
          <Grid.Row>
            <Label> {t("methodOfPayment")} </Label> {payment_activity.method_of_payment || "N/A"}
          </Grid.Row>
          <Grid.Row>
            <Label> {t("approverLabel")} </Label> {payment_activity.approver || "N/A"}
          </Grid.Row>
          <Grid.Row>
            <Label> {t("approvalDate")} </Label> {payment_activity.approval_date || "N/A"}
          </Grid.Row>
        </Segment>
      </React.Fragment>
    )
  }

  render() {
    const { children } = this.props
    return (
      <Modal
        size="tiny"
        trigger={
          React.Children.map(children, (c) =>
            React.cloneElement(c, { onClick: this.handleOpen })
          )[0]
        }
        open={this.state.modalOpen}
        closeIcon
        onClose={this.handleClose}
      >
        <Modal.Content>{this.renderView()}</Modal.Content>
      </Modal>
    )
  }
} // class PaymentActivityModal

const mapStateToProps = (state) => {
  const { payment_activity } = state
  return {
    payment_activity: state.paymentActivities.paymentActivity
  }
}

export default connect(mapStateToProps)(withTranslation("services")(PaymentActivityModal))
