import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import Alert, { ALERT_MESSAGE } from "../../components/shared/Alert"
import ApplicationLayout from "../../layouts/ApplicationLayout"
import LoadingError from "../../components/shared/LoadingError"
import LoadingThrobber from "../../components/shared/LoadingThrobber"
import MaintenancePolicies from "../../components/maintenance_policies/MaintenancePolicies"
import GeotabTelematicsData from "../../components/geotab_telematics/GeotabTelematicsData"
import { Segment, Menu, Header, Button } from "semantic-ui-react"
import UserList from "../../components/users/UserList"
import { isOwnerManagerOrAdmin } from "../../helpers/activeMaintenanceHelpers"
import { isFleetAdvise, supportedTelematicsSubDomains } from "../../helpers/affiliationHelpers"
import { SUPER_ADMIN } from "../../constants/roles"
import { withTranslation } from "react-i18next"
import AlertModal from "../../components/shared/AlertModal"
import { SETTINGS_USER } from "../../constants/application"
import { getSubdomain } from "../../components/shared/StylesheetInjector"
import { setupSplitFlags, ON } from "../../components/config/SplitClient"
import PaymentsIndex from "../payments/PaymentsIndex"
import WexPaymentList from "../../components/payments/WexPaymentList"
import PaymentList from "../../components/payments/PaymentList"
import { isBraintree, isWex, isFleetcor, isAch } from "../../helpers/userHelpers"
import { CANDA_COUNTRY_CODE, US_COUNTRY_CODE } from "../../constants/users"
import { SETTINGS_MENU_ITEMS } from "../../constants/menuItems"
import NotificationsReminderRef from "../accounts/NotificationsReminders"
import SettingsIndexShimmer from "./SettingsIndexShimmer"
import { logFirebaseAnalyticsScreenName } from "../../helpers/googleAnalyticsHelpers"
import { getRouteNameFromUrl } from "../../helpers/segmentHelpers"
import { routerPaths } from "../../constants/paths"
import { clearStateAfterTimeout } from "../../helpers/documentHelpers"

class SettingsIndex extends Component {
  notificationsReminderRef = React.createRef()
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isLoadingError: PropTypes.bool.isRequired,
    policies: PropTypes.array.isRequired,
    services: PropTypes.array.isRequired,
    users: PropTypes.array.isRequired,
    vehicles: PropTypes.array.isRequired,
    paymentMethods: PropTypes.array.isRequired
  }

  state = {
    activePage: SETTINGS_MENU_ITEMS.USERS,
    alertMessage: "",
    alertType: "default",
    isSubmitting: false,
    openAlertModal: false,
    userIdToDelete: null,
    openAlertModalDeactivate: false,
    userIdToDeactivate: null,
    status: true,
    isSettingChanged: false
  }

  async componentDidMount() {
    logFirebaseAnalyticsScreenName(getRouteNameFromUrl())

    await setupSplitFlags.bind(this)({ sub_domain: getSubdomain() })
    if (this.props.activeTab) {
      this.setState({ activePage: this.props.activeTab })
    } else if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state === 2
    ) {
      this.setState({ activePage: SETTINGS_MENU_ITEMS.REMINDERS })
    }
    this.loadUsers()
    this.loadRoles()
    this.loadVehicles()
    this.loadPolicies()
  }

  componentWillReceiveProps(newProps) {
    if (newProps.location && newProps.location.state && newProps.location.state == 1) {
      this.setState({ activePage: SETTINGS_MENU_ITEMS.POLICIES })
    }
  }

  defaultPages() {
    const { users, currentUser, dispatch, userRoles, states, policies } = this.props
    const { language } = currentUser
    return [
      {
        name: SETTINGS_MENU_ITEMS.USERS,
        label: "usersLabel",
        component: () => {
          return (
            <UserList
              users={users.filter((u) => {
                if (u.roles == null) return false

                return !u.roles.find((role) => {
                  // Roles seems to sometimes be coming in as an object, sometimes as a string.
                  // TODO: standardize this
                  return [role, role.name].find((r) => [SUPER_ADMIN].includes(r))
                })
              })}
              onDelete={this.onDeleteUser}
              onDeactivate={this.onDeactivateUser}
              loadUsers={this.loadUsers}
              currentUser={currentUser}
              dispatch={dispatch}
              language={language}
              userRoles={userRoles}
              states={states}
              isGloveboxFeatureFlag={this.state.isGloveboxFeatureFlag}
            />
          )
        }
      },
      {
        name: SETTINGS_MENU_ITEMS.POLICIES,
        label: "policiesLabel",
        component: () => (
          <MaintenancePolicies
            key={`policies-${policies.length || 0}`}
            isSubmitting={this.state.isSubmitting}
            onDelete={this.onDeletePolicy}
            onSubmit={this.onSubmitPolicy}
            policies={policies || []}
            services={this.props.services}
            users={this.props.users}
            vehicles={this.props.vehicles}
            currentUser={this.props.currentUser}
            language={this.props.language}
          />
        )
      }
    ]
  }

  allPages(currentUser, fleet) {
    const { fleet_payment_providers } = fleet
    let pages = this.defaultPages(currentUser)

    if (this.state.isMaintenanceScheduleFlag && this.state.isMaintenanceScheduleFlag == ON) {
      pages.push(this.maintenanceReminderPage())
    }

    if (
      fleet_payment_providers &&
      fleet_payment_providers.length > 0 &&
      fleet &&
      fleet.pays_through_platform &&
      isOwnerManagerOrAdmin(currentUser)
    ) {
      if (fleet_payment_providers.length == 1 && fleet_payment_providers.includes("fleetcor")) {
      } else pages.push(this.paymentsPage(currentUser))
    }

    if (supportedTelematicsSubDomains) {
      pages.push(this.geotabTelematicsDatabasePage())
    }

    return pages
  }

  maintenanceReminderPage() {
    return {
      name: SETTINGS_MENU_ITEMS.REMINDERS,
      label: "remindersLabel",
      component: () => (
        <NotificationsReminderRef
          ref={this.notificationsReminderRef}
          dispatch={this.props.dispatch}
          onSubmitPreferences={this.onSubmitPreferences}
          currentUser={this.props.currentUser}
          isPreferenceChanged={this.isPreferenceChanged}
        />
      )
    }
  }

  geotabTelematicsDatabasePage() {
    return {
      name: SETTINGS_MENU_ITEMS.GEOTAB_TELEMATICS_DATA,
      label: "shellTelematicsLabel",
      component: () => (
        <GeotabTelematicsData
          isSubmitting={this.state.isSubmitting}
          onSubmitDatabaseName={this.onSubmitGeotabDatabaseName}
          onCreateGeotabSyncEvent={this.onCreateGeotabSyncEvent}
        />
      )
    }
  }

  wexPaymentsPage(currentUser, currentFleet) {
    const { t } = this.props
    return {
      name: SETTINGS_MENU_ITEMS.WEX,
      label: "wexPaymentLabel",
      component: () => <WexPaymentList currentUser={currentUser} currentFleet={currentFleet} />
    }
  }

  braintreePaymentsPage(currentUser, currentFleet, braintree_label) {
    const { users, t } = this.props
    return {
      name: SETTINGS_MENU_ITEMS.BRAINTREE,
      label: braintree_label,
      component: () => (
        <PaymentList
          users={users}
          currentUser={currentUser}
          setAlert={this.onRequestFailure}
          currentFleet={currentFleet}
        />
      )
    }
  }

  paymentsPage(currentUser) {
    const { fleet } = this.props
    let pages = []
    let braintree_label = "creditCardLabel"

    if (isBraintree(fleet) && isAch(fleet)) braintree_label = "creditCardWithAchLabel"
    else if (isBraintree(fleet)) braintree_label = "creditCardLabel"
    else if (isAch(fleet)) braintree_label = "achLabel"
    if (isBraintree(fleet) || isAch(fleet))
      pages.push(this.braintreePaymentsPage(currentUser, fleet, braintree_label))

    if (
      isWex(fleet) &&
      fleet &&
      fleet.pays_through_platform &&
      !isFleetcor() &&
      isOwnerManagerOrAdmin(currentUser)
    )
      pages.push(this.wexPaymentsPage(currentUser, fleet))

    return {
      name: SETTINGS_MENU_ITEMS.PAYMENTS,
      label: "paymentLabel",
      component: () => <PaymentsIndex currentUser={currentUser} pages={pages} {...this.props} />
    }
  }

  handlePageChange = async (page) => {
    await this.setState({ activePage: page })
    if (this.props.activeTab) {
      this.props.history.push(routerPaths.settingsIndex)
    }
  }

  afterRequest(status, data) {
    this.setState({ isSubmitting: false })

    if (status === "success") {
      this.onRequestSuccess(data)
    } else {
      this.onRequestFailure(data)
    }
  }

  loadRoles = () => {
    this.props.dispatch({
      type: "LOAD_ROLES"
    })
  }

  loadVehicles = () => {
    this.props.dispatch({
      type: "VEHICLES_BASIC_LOAD_SAGA"
    })
  }

  loadUsers = () => {
    this.props.dispatch({
      type: "USERS_LOAD_SAGA"
    })
  }

  loadPolicies = async () => {
    const { dispatch } = this.props
    await dispatch({
      type: "MAINTENANCE_POLICIES_LOAD_SAGA"
    })
  }

  // fetchStates = () => {
  //   const { dispatch } = this.props
  //   const isShellCanada = isFleetMaintenanceHubCanada()
  //   dispatch({
  //     type: "FETCH_ALL_STATES",
  //     payload: {
  //       country: isShellCanada ? CANDA_COUNTRY_CODE : US_COUNTRY_CODE
  //     }
  //   })
  // }

  onDeleteUser = (user) => {
    this.setState({ openAlertModal: true, userIdToDelete: user.id, user: user })
  }

  onDeactivateUser = (user, status) => {
    this.setState({
      openAlertModalDeactivate: true,
      userIdToDeactivate: user.id,
      status: status,
      user: user
    })
  }

  onDeleteUserSuccess(data) {
    this.props.history.push({
      state: {
        alertMessage: data.alertMessage,
        alertType: data.alertType
      }
    })
  }

  afterDeleteUser(status, data) {
    if (status === "success") {
      this.onDeleteUserSuccess(data)
    } else {
      this.onRequestFailure(data)
    }
  }

  handleModalState = () => {
    this.setState({ openAlertModal: false })
  }
  handleDeactivateModalState = () => {
    this.setState({ openAlertModalDeactivate: false })
  }

  onDeletePolicy = (policyId) => {
    const { dispatch, t } = this.props
    this.setState({ isSubmitting: true })

    dispatch({
      type: "MAINTENANCE_POLICY_DELETE_SAGA",
      payload: { policyId: policyId, t: t },
      callback: this.afterRequest.bind(this)
    })
  }

  onCreateGeotabSyncEvent = () => {
    this.setState({ isSubmitting: true })
    this.props.dispatch({
      type: "CREATE_GEOTAB_SYNC_EVENT_SAGA",
      callback: this.afterRequest.bind(this)
    })
  }

  onSubmitGeotabDatabaseName = (databaseName) => {
    this.setState({ isSubmitting: true })
    this.props.dispatch({
      type: "FLEET_UPDATE_SAGA",
      payload: { id: this.props.fleet.id, databaseName, t: this.props.t },
      callback: this.afterRequest.bind(this)
    })
  }

  onRequestFailure = (data) => {
    this.setState({
      alertMessage: data.alertMessage,
      alertType: data.alertType
    })
  }

  onRequestSuccess(data) {
    this.setState({
      alertMessage: data.alertMessage,
      alertType: data.alertType
    })

    this.loadPolicies()
  }

  onSubmitPolicy = (formData) => {
    this.setState({ isSubmitting: true })

    let sagaType

    if (formData.id === null) {
      sagaType = "MAINTENANCE_POLICY_CREATE_SAGA"
    } else {
      sagaType = "MAINTENANCE_POLICY_UPDATE_SAGA"
    }

    this.props.dispatch({
      type: sagaType,
      payload: { formData: formData, t: this.props.t },
      callback: this.afterRequest.bind(this)
    })
  }

  saveReminderPreference = () => {
    if (this.notificationsReminderRef.current) {
      const response = this.notificationsReminderRef.current()
    }
  }

  onSubmitPreferences = (value) => {
    this.setState({ isSubmitting: value })
  }
  isPreferenceChanged = (value) => {
    this.setState({ isSettingChanged: value })
  }

  shouldRenderContent() {
    const { isLoading, isLoadingError } = this.props
    const { isSubmitting } = this.state

    return (
      (!isLoading && !isLoadingError && !isSubmitting) ||
      (!isLoadingError && this.state.activePage === SETTINGS_MENU_ITEMS.PAYMENTS)
    )
  }

  renderContent(currentUser) {
    const { isLoading, t, fleet } = this.props
    const { isSubmitting, activePage, isSettingChanged } = this.state
    const pages = this.allPages(currentUser, fleet)
    const page = pages.find((p) => p.name === activePage)
    return (
      <div className="settings-container">
        <span />
        <Header size="small" className={"dash-hd"}>
          {t("settingsLabel")}
        </Header>
        <Segment.Group>
          <Segment className="menu-container">
            <Menu pointing secondary stackable>
              {pages.map((page) => {
                return (
                  <Menu.Item
                    name={t(page.label)}
                    active={page.name === activePage}
                    onClick={this.handlePageChange.bind(this, page.name)}
                    className={`${isFleetAdvise() ? "menu-theme-color font-weight" : ""}`}
                  />
                )
              })}
              {activePage === SETTINGS_MENU_ITEMS.REMINDERS && (
                <Menu.Item position="right" className="skinny-menu-button">
                  <div>
                    <Button
                      floated="right"
                      content="Save Changes"
                      onClick={this.saveReminderPreference}
                      loading={isSubmitting}
                      disabled={!isSettingChanged}
                    />
                  </div>
                </Menu.Item>
              )}
            </Menu>
          </Segment>
          <Segment className="tab-container">
            {![SETTINGS_MENU_ITEMS.PAYMENTS, SETTINGS_MENU_ITEMS.GEOTAB_TELEMATICS_DATA].includes(
              activePage
            ) &&
            (isSubmitting || isLoading) ? (
              isFleetAdvise() ? (
                <SettingsIndexShimmer />
              ) : (
                <LoadingThrobber visible />
              )
            ) : (
              page.component()
            )}
          </Segment>
        </Segment.Group>
      </div>
    )
  }

  render() {
    const { isLoading, isLoadingError, currentUser } = this.props

    const {
      alertMessage,
      alertType,
      openAlertModal,
      userIdToDelete,
      openAlertModalDeactivate,
      userIdToDeactivate,
      status,
      user
    } = this.state

    return (
      <ApplicationLayout>
        {alertMessage && (
          <Alert
            message={alertMessage}
            type={alertType}
            clearStateAfterTimeout={() =>
              clearStateAfterTimeout(this.setState.bind(this), ALERT_MESSAGE)
            }
          />
        )}

        {!isLoading && isLoadingError ? <LoadingError visible /> : this.renderContent(currentUser)}
        {openAlertModal ? (
          <AlertModal
            openAlertModal={openAlertModal}
            hideModal={this.handleModalState}
            idToDelete={userIdToDelete}
            afterDelete={this.afterDeleteUser}
            that={this}
            modelFrom={SETTINGS_USER}
            user={user}
          />
        ) : null}
        {openAlertModalDeactivate ? (
          <AlertModal
            openAlertModal={openAlertModalDeactivate}
            hideModal={this.handleDeactivateModalState}
            idToDeactivate={userIdToDeactivate}
            userStatus={status}
            afterDelete={this.afterDeleteUser}
            that={this}
            modelFrom={SETTINGS_USER}
            user={user}
          />
        ) : null}
      </ApplicationLayout>
    )
  }
} // class SettingsIndex

const mapStateToProps = (state) => ({
  isLoading: state.application.isLoading,
  isLoadingError: state.application.isLoadingError,
  policies: state.maintenancePolicies.policies,
  services: state.services.services,
  users: state.users.users,
  userRoles:
    state.application.userSessionData &&
    state.application.userSessionData.roles &&
    state.users.userRoles,
  vehicles: state.vehicles.basicVehicles,
  fleet: state.application.fleetSessionData || state.fleets.fleet,
  currentUser: state.application.userSessionData || state.users.currentUser,
  language:
    (state.application.userSessionData && state.application.userSessionData.language) ||
    state.users.currentUser
      ? state.users.currentUser.language
      : "",
  states: state.users.states
})

export default connect(mapStateToProps)(withTranslation("settings")(withRouter(SettingsIndex)))
