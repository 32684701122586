import React, { Component } from "react"
import PropTypes, { object } from "prop-types"
import {
  Modal,
  Button,
  Header,
  Segment,
  Form,
  Message,
  Divider,
  Radio,
  Grid
} from "semantic-ui-react"
import * as stringHelpers from "../../helpers/stringHelpers"
import SpecializedFormInput from "./SpecializedFormInput"
import { withTranslation } from "react-i18next"
import { isEqual } from "lodash"
import {
  FMC,
  FMC_FLEET_ADMIN,
  FMC_FLEET_MANAGER,
  ACCOUNT_OWNER,
  FLEET_MANAGER,
  DRIVER,
  DRIVER_FR
} from "../../constants/roles"
import {
  isFleetAdvise,
  isFleetMaintenanceHubCanada,
  isFleetcor,
  isFleetAmerica
} from "../../helpers/affiliationHelpers"
import * as userHelpers from "../../helpers/userHelpers"
import { EDIT, VEHICLE_EDIT_OBJECT_NAME, USER_PREFERENCES } from "../../constants/application"
import { isEmpty } from "lodash"

class EditForm extends Component {
  static propTypes = {
    getNewFormState: PropTypes.func.isRequired,
    editFields: PropTypes.object,
    allFields: PropTypes.object.isRequired,
    saga: PropTypes.string.isRequired,
    object: PropTypes.object.isRequired,
    objectName: PropTypes.string.isRequired,
    completionCallback: PropTypes.func.isRequired,
    asModal: PropTypes.bool
  }

  static defaultProps = {
    object: {},
    objectName: "Record",
    completionCallback: () => {}
  }

  constructor(props) {
    super(props)

    this.resetState()

    this.handleSaveOnEnter = this.handleSave.bind(this, { isOnEnterCreate: false, validate: false })
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps, this.props)) {
      const state = this.state || {}
      if (this.props.object.id)
        state.steps = this.props.editFields ? this.props.editFields : this.props.allFields
      else state.steps = this.props.allFields

      if (!this.state) this.state = state
      else this.setState(state)
    }
  }

  resetState() {
    let state = this.state || {}
    state = {
      formData:
        this.props.flag == USER_PREFERENCES ? this.props.getNewFormState(this.props.object) : {},
      errors: {},
      submitErrors: [],
      currentStep: 1,
      showVin: false
    }

    if (this.props.object.id)
      state.steps = this.props.editFields ? this.props.editFields : this.props.allFields
    else state.steps = this.props.allFields

    if (!this.state) this.state = state
    else this.setState(state)
  }

  getAllFields = () => {
    let allFields = []
    const steps = this.state.steps
    Object.keys(steps).forEach((step) => (allFields = allFields.concat(steps[step])))
    return allFields
  }

  onFieldChange = async (field, possibleMomentObject, event) => {
    let updatedFormData = { ...this.state.formData }

    if (possibleMomentObject._isAMomentObject)
      updatedFormData[field.fieldName] = possibleMomentObject.format()
    else if (event.type === "checkbox")
      if (typeof updatedFormData[field.fieldName] === "object") {
        updatedFormData[field.fieldName][event.name].value = event.checked
      } else updatedFormData[field.fieldName][event.name] = event.value
    else updatedFormData[field.fieldName] = event.value

    this.setState({ formData: updatedFormData })
    let allFields = this.getAllFields()

    const dependentFields = allFields.filter(
      (f) =>
        f.field !== field.fieldName && f.dependent && f.dependent.indexOf(field.fieldName) !== -1
    )

    dependentFields.map((f) => (updatedFormData[f.field] = null))

    await Promise.all(
      dependentFields.map((f) => f.callback(...f.dependent.map((d) => updatedFormData[d])))
    )
  }

  onEnterCreateCall = async (field, event) => {
    if (event.key === "Enter" && !this.state.formData.id && field.onEnterCreateCall) {
      this.handleSaveOnEnter()
    }
  }

  afterloadSuccess = async (response) => {
    const { objectName } = this.props

    if (objectName == VEHICLE_EDIT_OBJECT_NAME) response.vehicle_name = response.name_of_vehicle
    else response = this.getNewFormState(response)

    this.setState({ formData: response })
  }

  getNewFormState = (user) => {
    let textNotificationPreference
    let emailNotificationPreference
    let defaultTextNotificationPreference = userHelpers.DEFAULT_TEXT_NOTIFICATION_PREFERENCE
    let defaultEmailNotificationPreference = userHelpers.DEFAULT_EMAIL_NOTIFICATION_PREFERENCE

    const { isFMC, currentUser } = this.props

    if (isFleetAmerica()) {
      delete defaultTextNotificationPreference.one_hour_reminder
    }

    if (user.text_notification_preference) {
      if (isFleetAmerica()) {
        delete user.text_notification_preference.one_hour_reminder
      }
      textNotificationPreference = { ...user.text_notification_preference }
      userHelpers.mapPreferencesToDefaults(
        textNotificationPreference,
        defaultTextNotificationPreference
      )
    }

    if (user.email_notification_preference) {
      emailNotificationPreference = { ...user.email_notification_preference }
      userHelpers.mapPreferencesToDefaults(
        emailNotificationPreference,
        defaultEmailNotificationPreference
      )
    }

    let roles = (user.roles || []).map((r) => (typeof r === "string" ? r : r.name))
    let newFormState = {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      cellPhone: user.cellPhone,
      other_id: user.other_id,
      roles: roles,
      vehicle_ids: user.vehicles
        ? user.vehicles.filter((v) => v.fleet_id == currentUser.fleet_id).map((v) => v.id)
        : [],
      approvalFleetIds: (user.approval_fleets || []).map((fleet) => fleet.id),
      payment_id: user.payment_id,
      text_notification_preference_attributes:
        textNotificationPreference || defaultTextNotificationPreference,
      email_notification_preference_attributes:
        emailNotificationPreference || defaultEmailNotificationPreference,
      address: user.address && user.address.address,
      city: user.address && user.address.city,
      state: user.address && user.address.state,
      garaging_zip: user.address && user.address.zip,
      address_id: user.address && user.address.id
    }
    if (isFMC) {
      newFormState = {
        ...newFormState,
        affiliationId: currentUser && currentUser.affiliation_id
      }
    }

    return newFormState
  }

  handleOpen = async () => {
    const { flag, editSaga, object } = this.props
    let formData = this.state.formData
    if (object.id && flag == EDIT) {
      this.props.dispatch({
        type: editSaga,
        payload: {
          id: parseInt(object.id)
        },
        callback: this.afterloadSuccess.bind(this)
      })
    } else if (Object.keys(formData).length === 0) {
      formData = this.getNewFormState(object)
    }
    if (formData) {
      this.setState({ modalOpen: true, loading: true, currentStep: 1, formData: formData })
      const allFields = this.getAllFields()
      const independentFields = allFields.filter((f) => !f.dependent && f.callback)
      const completedDependentFields = allFields.filter(
        (f) =>
          f.dependent &&
          f.dependent.filter((dependentOn) => formData[dependentOn] === null).length === 0
      )
      const tasks = [
        ...independentFields.map((f) => f.callback()),
        ...completedDependentFields.map((f) => f.callback(...f.dependent.map((d) => formData[d])))
      ]
      await Promise.all(tasks)
      // Dont judge me, dick. setState loading false causes a re-render way before the props update happens from redux.
      // This makes a nice little animation that people appreciate!
      setTimeout(() => this.setState({ loading: false }), this.props.object.id ? 1000 : 0)
    }
  }

  handleClose = () => {
    this.setState({ modalOpen: false })
    this.resetState()
  }

  handleSave = async ({ isOnEnterCreate = true, validate = true }) => {
    if (validate) {
      const errors = this.validate()
      if (Object.keys(errors).length > 0) {
        this.setState({ errors })
        return
      }
    }
    this.setState({ currentStep: this.state.currentStep++ })

    this.setState({ loading: true })

    let formData = { ...this.state.formData }
    const { objectName } = this.props
    //Transform text_notification_preferences nested checkbox data type back into normal
    if (objectName != VEHICLE_EDIT_OBJECT_NAME) {
      Object.keys(formData).forEach((k) => {
        if (typeof formData[k] === "object" && !Array.isArray(formData[k])) {
          formData[k] = { ...formData[k] }
          Object.keys(formData[k]).forEach(
            (checkboxInput) => (formData[k][checkboxInput] = formData[k][checkboxInput].value)
          )
        }
      })
    }

    let afterRequestData = await this.props.dispatch({
      type: this.props.saga,
      payload: { formData: formData, t: this.props.t },
      callback: this.afterFormSubmit.bind(this, isOnEnterCreate)
    })
  }

  afterFormSubmit = async (isOnEnterCreate, successState, data) => {
    const {
      dispatch,
      t,
      getNewFormState,
      completionCallback,
      currentUser,
      loadVehiclesStateSummary,
      isDashboard
    } = this.props
    if (data.error || data.errors || (data.alertMessage && data.alertType === "error"))
      this.setState({
        loading: false,
        submitErrors: [
          !isOnEnterCreate &&
          currentUser.roles &&
          (currentUser.roles.includes(DRIVER) || currentUser.roles.includes(DRIVER_FR))
            ? {
                message: data.error || data.errors || data.alertMessage,
                header: t("vehicle:vinLookUpValidationLabel")
              }
            : !isOnEnterCreate
            ? {
                message:
                  data.error || data.errors || data.alertMessage || t("vehicle:vinValidationLabel"),
                header: t("vehicle:vinLookUpValidationLabel")
              }
            : {
                message: data.error || data.errors || data.alertMessage,
                header: "Submission Failed"
              }
        ],
        currentStep: 1
      })
    else {
      if (!isOnEnterCreate) {
        await this.setState({
          formData: getNewFormState(data.vehicle),
          submitErrors: []
        })
        this.handleOpen()
      } else {
        this.setState({ loading: false, modalOpen: false, formData: {} })
        data.vehicle ? completionCallback(dispatch, data.vehicle.id) : completionCallback()
      }
      if (!isFleetMaintenanceHubCanada() && isOnEnterCreate) {
        await dispatch({
          type: "NEXT_MAINTENANCE_INTERVAL_SAGA",
          payload: { vehicleIds: [data.vehicle.id] }
        })
      }
      // this IF condition will only be executed when user is trying to create vehicle from dashboard.
      if (loadVehiclesStateSummary && isDashboard && isOnEnterCreate) {
        await loadVehiclesStateSummary()
      }
    }
  }

  validateEmail(str, fieldName, formData, t) {
    return !str || stringHelpers.isEmail(str)
      ? { success: true }
      : { error: t("emailValidationLabel") }
  }

  validatePhone(str, fieldName, formData, t) {
    return !str || stringHelpers.isPhoneNumber(str)
      ? { success: true }
      : { error: t("phoneValidationLabel") }
  }

  validateZip(str, fieldName, formData, t) {
    if (!str) {
      return { success: true }
    } else {
      return stringHelpers.isZip(str)
        ? { success: true }
        : {
            error: isFleetMaintenanceHubCanada()
              ? t("postalCodeValidationLabel")
              : t("zipCodeValidationLabel")
          }
    }
  }

  validatePassword(str, fieldName, formData, t) {
    const otherField = fieldName.match(/confirmation/)
      ? fieldName.replace("_confirmation", "")
      : fieldName + "_confirmation"
    if (formData[otherField] != str) return { error: t("passwordMatchLabel") }
    else return { success: true }
  }

  validate = () => {
    const { steps, currentStep, formData, showVin } = this.state
    const { deactivate, flag } = this.props
    const fieldsChecking = currentStep === 1 ? steps[currentStep] : null
    const { vin, license_plate_number, license_plate_state } = this.state.formData
    let errors = {}

    if (fieldsChecking !== null && deactivate) {
      if (showVin) {
        if (!vin) errors["vin"] = this.props.t("mustSelectLabel")
      } else {
        if (!license_plate_number) errors["license_plate_number"] = this.props.t("mustSelectLabel")
        if (!license_plate_state) errors["license_plate_state"] = this.props.t("mustSelectLabel")
      }
    } else {
      fieldsChecking !== null &&
        fieldsChecking.forEach((field) => {
          const val = formData[field.fieldName]

          if (!field.optional && ((!val && val !== false) || (Array.isArray(val) && isEmpty(val))))
            errors[field.fieldName] = this.props.t("mustSelectLabel")

          if (field.specialValidations)
            field.specialValidations.forEach((type) => {
              const validator = this["validate" + stringHelpers.capitalCase(type)]
              if (validator) {
                const result = validator(val, field.fieldName, formData, this.props.t)
                if (result.error) errors[field.fieldName] = result.error
              }
            })
        })

      if (currentStep === 2) {
        if (
          (vin === null || vin === "") &&
          (license_plate_number === null || license_plate_number === "") &&
          (license_plate_state === null || license_plate_state === "")
        ) {
          if (showVin && isFleetcor()) errors["vin"] = this.props.t("mustSelectLabel")
          if (!showVin) errors["vin"] = this.props.t("mustSelectLabel")
          else {
            errors["license_plate_number"] = this.props.t("mustSelectLabel")
            errors["license_plate_state"] = this.props.t("mustSelectLabel")
          }
        } else if (
          (vin === null || vin === "") &&
          (license_plate_number === null || license_plate_number === "") &&
          (license_plate_state !== null || license_plate_state !== "")
        ) {
          errors["license_plate_number"] = this.props.t("mustSelectLabel")
        } else if (
          (vin === null || vin === "") &&
          (license_plate_number !== null || license_plate_number !== "") &&
          (license_plate_state === null || license_plate_state === "")
        ) {
          errors["license_plate_state"] = this.props.t("mustSelectLabel")
        }
      }
    }

    return errors
  }

  handleNext = () => {
    const errors = this.validate()
    if (Object.keys(errors).length > 0) this.setState({ errors })
    else this.setState({ errors, currentStep: this.state.currentStep + 1 })
  }

  handleBack = () => {
    this.setState({ currentStep: this.state.currentStep - 1 })
  }

  handleChange = (e, showVin) => {
    this.setState({ showVin: showVin })
  }

  renderForm() {
    const { object, objectName, t, deactivate } = this.props
    const { steps, currentStep, formData, errors, loading, submitErrors, showVin } = this.state
    const anySubmitErrors = submitErrors.length > 0
    const without_fmc_roles = [FMC, FMC_FLEET_ADMIN, FMC_FLEET_MANAGER]
    const fleetcor_roles = [ACCOUNT_OWNER, FLEET_MANAGER]
    var disabledField =
      window.location.href.indexOf("settings") > -1 &&
      Object.keys(object).length > 0 &&
      object.roles != undefined &&
      object.roles.some((r) => without_fmc_roles.indexOf(r) >= 0)
    return (
      <React.Fragment>
        <Header textAlign="center" className="primary-color">
          {object.id && !deactivate
            ? t("editLabel") + `${objectName}`
            : t("addLabel") + `${objectName}`}
        </Header>
        <Form loading={loading} error={anySubmitErrors}>
          {submitErrors.map((err) => (
            <Message error={anySubmitErrors} header={err.header} content={err.message} />
          ))}
          {deactivate ? (
            <Segment>
              <Grid.Row>
                <Radio
                  label="License Plate details"
                  name="radioGroup"
                  value="false"
                  checked={this.state.showVin == false}
                  onChange={(e) => this.handleChange(e, false)}
                />
              </Grid.Row>
              <Grid.Row>
                <Radio
                  label="VIN"
                  name="radioGroup"
                  value="true"
                  checked={this.state.showVin == true}
                  onChange={(e) => this.handleChange(e, true)}
                />
              </Grid.Row>
            </Segment>
          ) : (
            ""
          )}
          {steps[currentStep].map((field, i) => (
            <>
              {window.location.href.indexOf("settings") > -1 ? (
                <>
                  {(Object.keys(object).length > 0 &&
                    object.roles != undefined &&
                    ((object.roles.some((r) => without_fmc_roles.indexOf(r) < 0) &&
                      field.fieldName != "approvalFleetIds") ||
                      object.roles.some((r) => without_fmc_roles.indexOf(r) >= 0))) ||
                  (object.id == undefined && field.fieldName != "approvalFleetIds") ? (
                    <SpecializedFormInput
                      key={`${field.fieldName}-i`}
                      formData={this.state.formData}
                      field={field}
                      onEnterCreateCall={this.onEnterCreateCall.bind(this, field)}
                      onFieldChange={this.onFieldChange.bind(this, field)}
                      outerProps={this.props /*For cache access*/}
                      error={errors[field.fieldName]}
                      disabled={
                        (disabledField &&
                          field.fieldName != "vehicle_ids" &&
                          field.fieldName != "address" &&
                          field.fieldName != "state" &&
                          field.fieldName != "city" &&
                          field.fieldName != "garaging_zip") ||
                        (object.roles != undefined &&
                          isFleetcor() &&
                          object.roles.some((r) => fleetcor_roles.indexOf(r) >= 0) &&
                          (field.fieldName == "email" || field.fieldName == "roles"))
                      }
                      t={t}
                    />
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <>
                  {(Object.keys(object).length > 0 &&
                    ((deactivate && !showVin && field.fieldName != "vin") ||
                      (deactivate && showVin && field.fieldName == "vin"))) ||
                  !deactivate ? (
                    <SpecializedFormInput
                      key={`${field.fieldName}-i`}
                      formData={this.state.formData}
                      field={field}
                      onEnterCreateCall={this.onEnterCreateCall.bind(this, field)}
                      onFieldChange={this.onFieldChange.bind(this, field)}
                      outerProps={this.props /*For cache access*/}
                      error={errors[field.fieldName]}
                      t={t}
                    />
                  ) : (
                    ""
                  )}
                </>
              )}
            </>
          ))}
        </Form>
      </React.Fragment>
    )
  }

  renderButtons() {
    const { steps, currentStep } = this.state
    const final = steps[currentStep + 1] === undefined
    const first = currentStep === 1
    const { object, t } = this.props

    return (
      <React.Fragment>
        {!first && (
          <Button
            floated="left"
            onClick={this.handleBack}
            className={isFleetAdvise() ? "btn-margin-right" : ""}
          >
            {t("backLabel")}
          </Button>
        )}
        {first ? (
          <div className={isFleetAdvise() ? "add-vehicle-next-button-parent" : ""}>
            <Button
              onClick={final ? this.handleSave : this.handleNext}
              className={isFleetAdvise() ? "add-vehicle-next-button add-vehicle-next-width" : ""}
            >
              {final ? (object.id ? t("saveLabel") : t("addLabel")) : t("nextLabel")}
            </Button>
          </div>
        ) : (
          <Button
            onClick={final ? this.handleSave : this.handleNext}
            className={isFleetAdvise() ? "add-vehicle-next-button" : ""}
          >
            {final ? (object.id ? t("saveLabel") : t("addLabel")) : t("nextLabel")}
          </Button>
        )}
      </React.Fragment>
    )
  }

  renderFlat() {
    return (
      <Segment>
        {this.renderForm()}
        <Divider hidden />
        {this.renderButtons()}
      </Segment>
    )
  }

  renderModal() {
    const { children } = this.props
    return (
      <Modal
        size="mini"
        closeOnDimmerClick={false}
        trigger={
          React.Children.map(children, (c) =>
            React.cloneElement(c, {
              onClick: (event) => {
                event.preventDefault()
                this.handleOpen(event)
              }
            })
          )[0]
        }
        open={this.state.modalOpen}
        closeIcon
        onClose={this.handleClose}
      >
        <Modal.Content>{this.renderForm()}</Modal.Content>
        <Modal.Actions className={isFleetAdvise() ? "add-vehicle-btn-parent" : ""}>
          {this.renderButtons()}
        </Modal.Actions>
      </Modal>
    )
  }

  render() {
    if (this.props.asModal) return this.renderModal()
    else return this.renderFlat()
  }
} // class EditForm

export default withTranslation("common")(EditForm)
