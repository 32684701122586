import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import { navigationPaths } from "../../constants/paths"

import Alert, { ALERT_MESSAGE } from "../../components/shared/Alert"
import ApplicationLayout from "../../layouts/ApplicationLayout"
import LoadingError from "../../components/shared/LoadingError"
import LoadingThrobber from "../../components/shared/LoadingThrobber"
import ServiceRequestForm from "../../components/service_requests/ServiceRequestForm"
import { withTranslation } from "react-i18next"
import { logFirebaseAnalyticsScreenName } from "../../helpers/googleAnalyticsHelpers"
import { getRouteNameFromUrl } from "../../helpers/segmentHelpers"
import { clearStateAfterTimeout } from "../../helpers/documentHelpers"

class ServiceRequestNew extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isLoadingError: PropTypes.bool.isRequired,
    favoriteShops: PropTypes.array.isRequired
  }

  state = {
    isSubmitting: false,
    alertMessage: "",
    alertType: ""
  }

  componentDidMount() {
    logFirebaseAnalyticsScreenName(getRouteNameFromUrl())

    const { favoriteShops, location } = this.props
    const data = location.state && location.state.previousFormData
    const vehicle = (data || {}).vehicle
    let payload = { favoriteShops: favoriteShops }
    if (vehicle) payload.vehicle = vehicle

    this.props.dispatch({
      type: "SHOPS_LOAD_SAGA",
      payload: {
        isV4: true
      }
    })

    this.props.dispatch({
      type: "SERVICE_REQUEST_LOAD_SAGA",
      payload: {}
    })

    this.props.dispatch({
      type: "FETCH_USERS_BASIC_DETAILS"
    })
  }

  checkPaymentMethod() {
    const currentUser = this.props.currentUser || {}
    const { has_payment_method, pays_through_platform } = currentUser

    if (pays_through_platform && !has_payment_method) {
      this.setState({
        alertMessage: this.props.t("scheduleRequirePaymentDesc"),
        alertType: "warning"
      })
    } else return false
  }

  onFormSubmit(formData) {
    this.props.history.push({
      pathname: navigationPaths.serviceRequestNew(),
      state: {
        previousFormData: formData
      }
    })
    this.props.history.push({
      pathname: navigationPaths.serviceRequestSchedule(),
      state: {
        previousFormData: formData
      }
    })
  }

  gotoShopSearch = (formData) => {
    this.props.history.push({
      pathname: navigationPaths.serviceRequestNew(),
      state: {
        previousFormData: formData
      }
    })
    this.props.history.push({
      pathname: navigationPaths.shopIndex(),
      state: {
        previousFormData: formData
      }
    })
  }

  shouldRenderContent() {
    const { isLoading, isLoadingError } = this.props

    return !isLoading && !isLoadingError
  }

  renderContent() {
    let { favoriteShops } = this.props

    const {
      customerComments,
      isSubmitting,
      selectedServices,
      shopId,
      vehicleId,
      shop,
      vehicle
    } = this.state
    if (this.shouldRenderContent()) {
      return (
        <>
          <ServiceRequestForm
            isSubmitting={isSubmitting}
            onSubmit={this.onFormSubmit.bind(this)}
            previousFormData={
              (this.props.location.state && this.props.location.state.previousFormData) || {}
            }
            favoriteShops={favoriteShops}
            gotoShopSearch={this.gotoShopSearch}
          />
        </>
      )
    } else {
      return null
    }
  }

  render() {
    const { isLoading, isLoadingError, t } = this.props
    const { alertMessage, alertType } = this.state

    return (
      <ApplicationLayout>
        {!isLoading && (alertMessage !== "" || this.checkPaymentMethod()) && (
          <Alert
            message={alertMessage}
            type={alertType}
            clearStateAfterTimeout={() =>
              clearStateAfterTimeout(this.setState.bind(this), ALERT_MESSAGE)
            }
          />
        )}

        <LoadingThrobber visible={isLoading} />

        <LoadingError visible={!isLoading && isLoadingError} />

        {this.renderContent()}
      </ApplicationLayout>
    )
  }
} // class ServiceRequestNew

const mapStateToProps = (state) => ({
  isLoading: state.application.isLoading,
  isLoadingError: state.application.isLoadingError,
  favoriteShops: state.shops.favoriteShops,
  currentUser: state.users.currentUser || state.application.userSessionData
})

export default connect(mapStateToProps)(withTranslation("scheduleRequest")(ServiceRequestNew))
