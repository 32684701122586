import JSZip from "jszip"

export function handleDownload(files) {
  files.forEach((file) => {
    const { url } = file
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network Error")
        }
        return response.blob()
      })
      .then((blob) => {
        const blobURL = window.URL.createObjectURL(blob)
        const link = document.createElement("a")
        link.href = blobURL
        link.setAttribute("download", getFileNameFromUrl(url))
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
      .catch((error) => {
        console.error("Error:", error)
      })
  })
}

export function handleDownloadAll(files) {
  const zip = new JSZip()

  const filePromises = files.map((file) => {
    const fileName = new URL(file.url).pathname.split("/").pop()
    return fetch(file.url)
      .then((response) => {
        if (response.ok) {
          return response.blob()
        } else {
          throw new Error(`Error with status ${response.status}, url: ${response.url}`)
        }
      })
      .then((blob) => {
        return zip.file(fileName, blob)
      })
  })

  Promise.all(filePromises)
    .then(() => {
      return zip.generateAsync({ type: "blob" })
    })
    .then((content) => {
      const url = window.URL.createObjectURL(content)
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", "invoice_documents.zip")
      document.body.appendChild(link)
      link.click()
      link.remove()
    })
    .catch((error) => {
      console.error("Error:", error)
    })
}

export const getFileNameFromUrl = (url) => {
  return new URL(url).pathname.split("/").pop()
}

// Helper function to clear state after a timeout
export const clearStateAfterTimeout = (setState, stateName) => {
  setState({ [stateName]: "" }) // Clear the specified state
}
