import React from "react"
import ScheduleServiceButton from "./ScheduleServiceButton"
import AddMaintenanceHistoryButton from "./AddMaintenanceHistoryButton"

import { Header, Button } from "semantic-ui-react"

export default function ScheduleServiceHeader({ header }) {
  return (
    <Header
      size="small"
      style={{
        marginBottom: "4px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingRight: "2rem"
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
        {header}
      </div>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "right" }}>
        <AddMaintenanceHistoryButton />
        <ScheduleServiceButton />
      </div>
    </Header>
  )
}
