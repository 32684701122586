import React from "react"
import PropTypes from "prop-types"
import { Button, Image } from "semantic-ui-react"

const CustomButton = ({
  className,
  onClick,
  disabled,
  iconSrc,
  label,
  labelTranslation,
  iconClassName
}) => {
  return (
    <Button className={className} onClick={onClick} disabled={disabled}>
      {iconSrc && <Image src={iconSrc} className={iconClassName} alt="icon" />}
      {labelTranslation ? labelTranslation(label) : label}
    </Button>
  )
}

CustomButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  iconSrc: PropTypes.string,
  label: PropTypes.string.isRequired,
  labelTranslation: PropTypes.func,
  iconClassName: PropTypes.string
}

CustomButton.defaultProps = {
  className: "",
  disabled: false,
  iconSrc: null,
  labelTranslation: null,
  iconClassName: ""
}

export default CustomButton
