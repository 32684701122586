import React, { Component } from "react"
import PropTypes from "prop-types"

const TOASTER_VISIBILITY_TIMER = 5000

export const ALERT_MESSAGE = "alertMessage"
export const ALERT = "alert"
export const ERROR = "error"

class Alert extends Component {
  // Type Map:
  // {
  //   'default' => grey,
  //   'error'   => red,
  //   'notice'  => blue,
  //   'success' => green,
  //   'warning' => yellow,
  // }

  // NOTE: ".alert" CSS class will default to same styling alert--default, it is used for context.

  static propTypes = {
    message: PropTypes.string.isRequired,

    type: PropTypes.string,
    visible: PropTypes.bool,
    extraType: PropTypes.string
  }

  static defaultProps = {
    type: "default",
    visible: true,
    extraType: ""
  }

  componentDidMount() {
    this.timeoutId = setTimeout(() => {
      this.setState(
        {
          toasterVisible: false
        },
        () => {
          this.props.clearStateAfterTimeout && this.props.clearStateAfterTimeout()
        }
      )
    }, TOASTER_VISIBILITY_TIMER)
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId) // Clear the timeout
    }
  }

  render() {
    const { message, type, visible, children, extraType } = this.props

    if (visible) {
      return (
        <div className={`alert alert--${type} alert--${extraType}`}>
          {message}
          {children}
        </div>
      )
    } else {
      return null
    }
  }
} // class Alert

export default Alert
